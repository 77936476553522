import tipx from './logos/tipx.png';
import tuktuk from './logos/tuktuk.png';
import cornerstone from './logos/cornerstone.png';
import root from './logos/root.png';
import alois from './logos/alois.png';
import gazelle from './logos/gazelle.png';
import populus from './logos/populus.png';
import scraps from './logos/scraps.png';
import cacao from './logos/cacao.png';
import locol from './logos/locol.png';
import sensibed from './logos/sensibed.png';
import flightdeck from './logos/flightdeck.png';

// TODO missing Three0
// TODO add founders for recent cohort

const currentPortfolioInfo = [
      {
        name: "Resia",
        logo: "Resia_Logo.png",
        description: 'Resia is revolutionizing the $550B AEC industry with AI-driven 3D modeling and real-time cost estimation.',
      },
      {
        name: "CURA",
        logo: "Cura_Logo.png",
        description: 'Cura is an AI-powered app providing tailored support for family caregivers of mentally ill.'
      },
      {
        name: "HouseTabz",
        logo: "HouseTabz_Logo.png",
        description: 'HouseTabz streamlines expenses in shared living, removing the need for a roommate to front expenses.'
      },
      {
        name: "SpeciaList",
        logo: "SpeciaList_Logo.png",
        description: 'SpeciaList is a subscription-based healthcare platform and physician network.'
      },
      {
        name: "Suits",
        logo: "Suits_Logo.png",
        description: 'Suits helps users join, create, and claim class action settlements.'
      },
      {
        name: "Phenom",
        logo: "Phenom_Logo.png",
        description: 'Phenom is a seamless online marketplace connecting student-athletes with top-tier media creatives.'
      },
      {
        name: "cacao.tax",
        logo: "cacao.png",
        description: 'cacao.tax is a duty-free marketplace for international travelers.'
      },
      {
        name: "Thrive Education",
        logo: "Thrive_Logo2.png",
        description: 'Thrive Education personalizes diabetes education through AI and provider-patient interaction.'
      },
]

export default currentPortfolioInfo;