import styled from "styled-components";
import NextSection from "../general/NextSection";
import { colors } from "../../assets/colors";
import { useCursorHandlers, useSectionCursor } from "../general/Cursor";
import { useState } from "react";

import logo from "../../assets/logo.png";
import neha0 from "../../assets/headshots/neha0.png";
import neha1 from "../../assets/headshots/neha1.png";
import ryan0 from "../../assets/headshots/ryan0.png";
import ryan1 from "../../assets/headshots/ryan1.png";
import lucas0 from "../../assets/headshots/lucas0.png";
import lucas1 from "../../assets/headshots/lucas1.png";
import riya0 from "../../assets/headshots/riya0.png";
import riya1 from "../../assets/headshots/riya1.png";
import ronnie0 from "../../assets/headshots/ronnie0.jpeg";
import ronnie1 from "../../assets/headshots/ronnie1.png";
import saood0 from "../../assets/headshots/saood1.jpeg";
import athena0 from "../../assets/headshots/athena1.jpg";
import akhilesh0 from "../../assets/headshots/akhilesh1.jpg";
import michelle0 from "../../assets/headshots/michelle1.jpg";


const SectionContainer = styled.div`
  width: 100vw;
  background-color: ${colors.black};
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 0px;
  max-width: 100%;
`;

const TextContainer = styled.div`
  margin-left: 20vw;
  margin-right: 20vw;
`;

const TitleText = styled.h1`
  display: inline-block;
  font-size: 5vmin;
  font-family: ReadexPro, sans-serif;
  margin: 5vh 0 0 0;
  padding: 0;
  font-weight: 700;
  text-align: center;
  color: ${colors.red};
`;

const Cards = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  position: relative;
  margin-bottom: 5vh;
`;

const CardContainer = styled.div`
  height: 60vmin;
  width: 40vmin;
  padding: 2vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CardImage = styled.div`
  background-image: url(${logo});
  border: none;
  width: 36vmin;
  height: 44vmin;
  background-size: cover;
`;

const CardImageHover = styled.div`
  background-image: url(${logo});
  border: none;
  width: 36vmin;
  height: 44vmin;
  background-size: cover;
  transition: opacity 0.3s;
  margin-bottom: -100%;
  transform: translateY(-100%);
`;

const CardTitle = styled.h1`
  display: inline-block;
  font-size: 3vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-align: center;
  color: ${colors.white};
  @media only screen and (max-width: 700px) {
    font-size: 4.3vmin;
  }
`;

const CardText = styled.p`
  display: inline-block;
  font-size: 2.5vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-align: center;
  color: ${colors.white};
  @media only screen and (max-width: 700px) {
    font-size: 3.4vmin;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 50vh;
`;

const SmallSpacer = styled.div`
  width: 100%;
  height: 5vh;
`;
const data = [
  {
    name: "Ronnie Weaver",
    img0: ronnie0,
    img1: ronnie0,
    title: "President",
    url: "https://www.linkedin.com/in/ronnie-weaver-274a07228/",
    ID: 5
  },
  // {
  //   name: "Max Chen",
  //   img0: saood0,
  //   img1: saood0,
  //   title: "Executive VP",
  //   url: "https://www.linkedin.com/in/maxchen/",
  //   ID: 7
  // },
  {
    name: "Michelle Shin",
    img0: michelle0,
    img1: michelle0,
    title: "VP of Accelerator",
    url: "https://www.linkedin.com/in/michelle-shin-850045255/",
    ID: 10
  },
  {
    name: "Saood Usmani",
    img0: saood0,
    img1: saood0,
    title: "VP of Special Projects",
    url: "https://www.linkedin.com/in/saood-usmani/",
    ID: 8
  },
  // {
  //   name: "Akhilesh Bitla",
  //   img0: akhilesh0,
  //   img1: akhilesh0,
  //   title: "VP of Special Projects",
  //   url: "https://www.linkedin.com/in/akhileshbitla/",
  //   ID: 9
  // },

  {
    name: "Athena Wu",
    img0: athena0,
    img1: athena0,
    title: "VP of Community",
    url: "https://www.linkedin.com/in/athenawu3/",
    ID: 11
  }
]

function Text() {
  const sectionCursorHandlers = useSectionCursor({color: colors.white});
  const cursorHandlers = useCursorHandlers();
  const [hoverID, setHoverID] = useState(0);
  
  return ( 
    <SectionContainer {...sectionCursorHandlers}>
      <TextContainer>
        <TitleText>Executive Board</TitleText>
      </TextContainer>
      <Cards>
        {data.map((person, index) => (
          <a
            href={person.url}
            target="_blank"
            style={{ textDecoration: "none" }}
            {...cursorHandlers}
          >
            <CardContainer>
              <CardImage style={{ backgroundImage: `url(${person.img0})` }} />
              <CardImageHover
                onMouseEnter={() => setHoverID(person.ID)}
                onMouseLeave={() => setHoverID(0)}
                style={{
                  opacity: hoverID === person.ID ? "100%" : "0%",
                  backgroundImage: `url(${person.img1})`,
                }}
              />
              <CardTitle>{person.name}</CardTitle>
              <CardText>{person.title}</CardText>
            </CardContainer>
          </a>
        ))}
      </Cards>
    </SectionContainer>
  );
}

export default Text;